import React from "react";
import { SectionLink } from "react-scroll-section";
import styled from "styled-components";

const Menu = styled.ul`
  display: flex;
  align-items: center;
  justify-content:center;
  padding:0;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction:column;
  }
`;

const Item = styled.li`
display: inline;
margin: 0 1.5rem;
position: relative;
cursor: pointer;
&:before {
  content: "";
  width: 100%;
  height: 2px;
  background: #c6a15a;
  position: absolute;
  bottom: -4px;
  transition: all 0.4s linear 0s;
  transform: ${props => (props.selected ? "scale(1)" : "scale(0)")};  
}
&:hover {
  &:before {
    content: "";
    transform: scale(1);
  }
}
@media (max-width: ${props => props.theme.breakpoints.desktop}) {
  margin: 0 0.4rem;
}
color: ${props => (props.selected ? props.theme.primaryColor : props.theme.secondaryColor)};
@media (max-width: ${props => props.theme.breakpoints.tablet}) {
  display: block;
  margin-bottom:1rem;
}

`;

const MenuItem = ({ section, children }) => (
  <SectionLink section={section}>
    {link => (
      <Item onClick={link.onClick} selected={link.isSelected}>
        {children}
      </Item>
    )}
  </SectionLink>
);

export { MenuItem as Item, Menu };

export default styled;
