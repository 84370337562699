import React from 'react'
import styled from 'styled-components'


const Icon = styled.div`
position: relative;
display: block;
width: 2.3em;
height: 3em;
cursor: pointer;
-webkit-transition: all 0.3s;
transition: all 0.3s;
span {
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  height: 0.14em;
  background-color: ${props => props.theme.primaryColor};
  border-radius: 3px;
  &:before, &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.primaryColor};
    border-radius: 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  &:before {
    -webkit-transform: translateY(-1em);
    transform: translateY(-0.6em);
  }
  &:after {
    -webkit-transform: translateY(1em);
    transform: translateY(0.6em);
  }
}
&.open {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  span {
    &:before, &:after {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
}
`

const MenuIcon = (props) =>

<Icon className={props.opened} onClick={props.click}><span></span></Icon>

export default MenuIcon