import React from "react"
import styled from "styled-components"
import {
  Section,
  Container,
  Row,
  Col,
  Title,
  Subtitle,
  Text,
} from "../style/Global"

const AchievementsSection = styled(Section)`
    background: #111;
  padding: 5rem 0;
  margin-bottom: 0;
`

const List = styled.ul`
    margin: 0 2rem;
    padding: 0;
`

const ListItem = styled.li`
color: #999;
    line-height: 2rem;
`


const Achievements = () => {
  return (
    <AchievementsSection>
      <Container>          
        <Title center reversed>Nagrade</Title>
        <Subtitle center reversed>Pogledajte naše nagrade i priznanja</Subtitle>
        <Row>
          <Col width="50">
            <Text reversed>
                Na smotrama maslinara i uljara u Nadinu, Vodicama, Splitu i Zagrebu osvojene su zlatne i srebrne medalje.
            </Text>
          </Col>
          <Col width="50">
            <Text reversed>
                Uz medalje OPG-u Deša pripala su i brojna priznanja poput:
            </Text>
            <List>
                <ListItem>Priznanje Grb Grada Zadra</ListItem>
                <ListItem>Priznanje Općine Poličnik za doprinos razvoja maslinarstvu</ListItem>
                <ListItem>Godišnje priznanje 20. Međunarodnih Dana Masline Zadar 2018. - Priznanje „Stanko Ožanić“ </ListItem>
            </List>
          </Col>
        </Row>
      </Container>
    </AchievementsSection>
  )
}

export default Achievements
