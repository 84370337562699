import React from "react"
import styled from "styled-components"
import {
  Section,
  Container,
  Row,
  Col,
  Title,
  Subtitle,
  Text,
} from "../style/Global"
import vector1 from "../../images/vector1.png"
import vector2 from "../../images/vector2.png"
import vector3 from "../../images/vector3.png"
import vector4 from "../../images/vector4.png"
import vector5 from "../../images/vector5.png"
import vector6 from "../../images/vector6.png"

const ProductQualitySection = styled(Section)`
  background: #111;
  padding: 5rem 0;
  margin-bottom: 0;
`

const QtySection = styled.div`
  padding: 1rem;
  text-align: center;
`

const QtyIcon = styled.img``

const QtyTitle = styled.h3`
  text-transform: uppercase;
  color: #fff;
`

const QtySubtitle = styled.h4`
  color: ${props => props.theme.primaryColor};
`

const ProductQuality = () => {
  return (
    <ProductQualitySection>
      <Container>
        <Title center reversed>
          Kvaliteta naših proizvoda
        </Title>
        <Subtitle center reversed>
          Kako bi osigurali visoku kvalitetu ulja od samog početka vodimo brigu
          o svim čimbenicima koji na nju utječu
        </Subtitle>

        <Row>
          <Col width="33">
            <QtySection>
            <QtyIcon src={vector1} alt={vector1} />
            <QtyTitle>SORTA</QtyTitle>
            <QtySubtitle>Autohtona sorta oblica</QtySubtitle>
            <Text reversed>
              Za sadnju koristila se isključivo oblica ili orkula na vlastitom
              korijenu kao sortu najotporniju na lošije pedološke i hidrološke
              prilike (puno kamena, suša i dr.).
            </Text>
              </QtySection>            
          </Col>

          <Col width="33">
            <QtySection>
            <QtyIcon src={vector2} alt={vector2} />
            <QtyTitle>EKOLOŠKA POLJOPRIVREDA</QtyTitle>
            <QtySubtitle>Ekološko maslinarstvo</QtySubtitle>
            <Text reversed>
            Upotreba samo ekološki dopustivih sredstava za zaštitu i prihranu uz redovnu kontrolu ovlaštene nadzorne stanice (AgriBioCert).
            </Text>
              </QtySection>            
          </Col>

          <Col width="33">
            <QtySection>
            <QtyIcon src={vector3} alt={vector3} />
            <QtyTitle>BERBA</QtyTitle>
            <QtySubtitle>Ručna berba u nosiljke iz Kalifornije</QtySubtitle>
            <Text reversed>
            Veliku pažnju posvećujemo izboru pravog trenutka za berbu kako bi osigurali maksimalan sadržaj fitofenola u plodu masline i time osigurali maslinovo ulje visokog udjela.
            </Text>
              </QtySection>            
          </Col>

          <Col width="33">
            <QtySection>
            <QtyIcon src={vector4} alt={vector4} />
            <QtyTitle>SMJEŠTAJ MASLENIKA</QtyTitle>
            <QtySubtitle>Mikorklima lokacije pogoduje uzgoju maslinika</QtySubtitle>
            <Text reversed>
            Maslinik je smješten na odličnoj lokaciji za uzgoj maslina. Na nadmorskoj visini od 100 m. Od mora udaljeni 8-9 km. Na tom području su prisutna strujanja toplog morskog zraka iz pravca ninskog zaljeva  </Text>
              </QtySection>            
          </Col>

          <Col width="33">
            <QtySection>
            <QtyIcon src={vector5} alt={vector5} />
            <QtyTitle>SAMOODRŽIVA POLJOPRIVREDA</QtyTitle>
            <QtySubtitle>Odgovoran odnos prema zemlji, biljkama i životinjama</QtySubtitle>
            <Text reversed>
            Doveli smo kokoši u maslinik kako bi one svojim životnim navikama zaštitile i obogatile masline. Kokoše čeprkaju po tlu i jedu kukce te time smanjuju broj mogućih nametnika i štetočina koje bi mogle ugroziti masline.      </Text>
              </QtySection>            
          </Col>

          <Col width="33">
            <QtySection>
            <QtyIcon src={vector6} alt={vector6} />
            <QtyTitle>PRERADA I ČUVANJE</QtyTitle>
            <QtySubtitle>Osiguranje dugoročnog čuvanja vrijednih svojstava</QtySubtitle>
            <Text reversed>
            U procesu prerade primjenjuje se mehanički postupak (tiještenje) u hladnom okruženju (maksimalno zagrijavanje je do 27 celzijevih).    </Text>
              </QtySection>            
          </Col>
        </Row>
      </Container>
    </ProductQualitySection>
  )
}

export default ProductQuality
