import React from "react"
import styled from "styled-components"
import {
  Section,
  Container,
  Row,
  Col,
  Title,
  Text,
  Subtitle,
} from "../style/Global"
import oliveBottleImg from "../../images/olive_bottle.png"
import chickenImg from "../../images/koke.png"

const AboutUsSection = styled(Section)`
  padding: 5rem 0;
  margin-bottom: 0;
`

const ProductTitle = styled.h4`
  text-transform: uppercase;
  color: #000000;
`

const ProductImg = styled.img`
  max-width: 500px;
  width: 100%;
`

const Products = () => {
  return (
    <AboutUsSection>
      <Container>
        <Title center>Proizvodi</Title>
        <Subtitle center>Pogledajte naše proizvode</Subtitle>
        <Row style={{marginBottom: "3rem"}}>
          <Col width="50">
            <ProductImg src={oliveBottleImg} alt={oliveBottleImg} />
          </Col>
          <Col width="50">
            <ProductTitle>
              EKOLOŠKO EKSTRA DJEVIČANSKO MASLINOVO ULJE
            </ProductTitle>
            <Text>
              Deša ekološko ekstra djevičansko maslinovo ulje je ulje visoke
              kakvoće dobiveno izravno iz ploda masline isključivo mehaničkim
              postupcima
            </Text>
            <Text>
              Hladni postupak je procjeđivanje ili centrifugiranje maslinovog
              tijesta dobiveno pri temperaturi prerade nižoj od 27°C.
            </Text>
            <Text>
              Nakon prerade ulje se ne filtrira dodatno što mu daje
              karakterističan okus i mogućnost stvaranja taloga.
            </Text>
          </Col>         
        </Row>
        <Row>          
          <Col width="50">
            <ProductTitle>
                JAJA SRETNIH KOKA
            </ProductTitle>
            <Text>
            Jaja sretnih koka iz slobodnog uzgoja različitih veličina i boja minimalne neto težine 530 g A klase.
            </Text>
            <Text>
            Koke borave u ekološkom masleniku smještene u pomičnom hotelu za koke i svakih 15 dana sele na novu ispašu.
            </Text>
            <Text>
            Jedna koka ima čak 333 m2 dok ih od predatora štite maremma ovčari.
            </Text>
          </Col>   
          <Col width="50">
            <ProductImg src={chickenImg} alt={chickenImg} />
          </Col>      
        </Row>        
      </Container>    
    </AboutUsSection>
  )
}

export default Products
