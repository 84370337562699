import React, { useState, useCallback } from "react";
import styled from "styled-components"
import Carousel, { Modal, ModalGateway } from "react-images"
import GalleryGrid from "react-photo-gallery";
import { Container, Section,  Title, Subtitle } from "../style/Global"
import img1 from "../../images/gallery/berba.jpg"
import img2 from "../../images/gallery/maurema-cuvari.jpg"
import img3 from "../../images/gallery/desa-opg.jpg"
import img4 from "../../images/gallery/maslenik.jpg"
import img5 from "../../images/gallery/masline.jpg"
import img6 from "../../images/gallery/berba-maslina.jpg"
import img7 from "../../images/gallery/obrada-maslina.jpg"
import img8 from "../../images/gallery/opg-desa.jpg"

const GallerySection = styled(Section)`
  position: relative;
  padding: 5rem 0;
`

const photos = [
    {
      src: img1,
      width: 4,
      height: 3,
      alt: 'berba'
    },
    {
      src: img2,
      width: 1,
      height: 1,
      alt: 'berba-maslina'
    },
    {
      src: img3,
      width: 4,
      height: 3,
      alt: 'desa-opg'
    },
    {
      src: img4,
      width: 4,
      height: 3,
      alt: 'maslenik'
    },
    {
      src: img5,
      width: 4,
      height: 3,
      alt: 'masline'
    },
    {
      src: img6,
      width: 4,
      height: 3,
      alt: 'maurema-cuvari'
    },
    {
        src: img7,
        width: 4,
        height: 3,
        alt: 'obrada-maslina'
      },
      {
        src: img8,
        width: 4,
        height: 3,
        alt: 'opg-desa'
      }
  ];

const Gallery = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
  
    return (
      <GallerySection>
          <Container>
          <Title center>
          Galerija
        </Title>
        <Subtitle center>
          Pogledajte našu galeriju slika
        </Subtitle>
        <GalleryGrid photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        </Container>
      </GallerySection>
    );
  }

export default Gallery
