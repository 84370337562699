import React from "react"
import styled from "styled-components"
import {
  Section,
  Container,
  Row,
  Col,
  Title,
  Text,
} from "../style/Global"
import aboutUsPicture from '../../images/about.png'

const AboutUsSection = styled(Section)`
  padding: 5rem 0;
  margin-bottom: 0;
`

const AboutUsPicture = styled.img`
  width: 100%;
  max-width: 500px;
  padding:4rem;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding:0;
  }
`


const AboutUs = () => {
  return (
    <AboutUsSection>
      <Container>
        <Row>
          <Col width="50">
            <Title>O nama</Title>
            <Text>
              Mi smo obiteljsko poljoprivredno gospodarstvo Deša. Smješteni smo
              u Zadru. Uz povjesni značaj vezan za sakralnu kulturu značajno je
              sjedište poljoprivredne proizvodnje. Duga tradicija proizvodnje
              hrane u Ravnim kotarima rezultira bogatstvom autohtonih vrsta
              mnogih vrsta voća i povrća. Ideja vodilja sadnje maslinika je
              održivi suživot s prirodom, te ekološka održiva proizvodnja
              orjentirana na autohtone vrste.
            </Text>
            <Text>
              Glavni cilj: Kupcima ponuditi proizvode vrhunske kvalitete.
            </Text>
            <Text>
              Čuvamo biološku raznolikost, potičemo razvoj prirodnih
              neprijatelja, štetočina jer je to i nejekonomičniji način zaštite
              bilja. Posađena je autohtona sorta oblica koja se pokazala u našim
              klimatskim uvjetima na našoj geografskoj lokaciji kao najotpornija
              na štetočine i bolest.
            </Text>
            <Text>
              Cijeli projekt zamišljen je po principima ekološke samoodržive
              proizvodnje prvenstveno autohtonih proizvoda. Odgovoran odnos
              prema zemlji, biljkama i životinjama rezultira vrhunskim
              proizvodima (maslinovim uljem). Za nas maslinovo ulje nije samo
              proizvod u kojem želimo postići vrhunsku kvalitetu već želimo
              probuditi svjesnost o njegovoj povijesno dokazanoj snazi
              ozdravljenja. Hrana je lijek.
            </Text>
          </Col>

          <Col width="50">
            <AboutUsPicture  src={aboutUsPicture} alt={aboutUsPicture}/>
          </Col>
        </Row>
      </Container>
    </AboutUsSection>
  )
}

export default AboutUs
