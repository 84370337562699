import React from "react"
import styled from "styled-components"
import BacktoTop from "react-back-to-top-button"
import IconToTop from '../assets/angle-double-up.svg'

const ToTop = styled.span`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.reversedColor};
  opacity: 0.85;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
  border: 1px solid ${props => props.theme.primaryColor};
  svg {
    max-width: 30px;
  }
`

const BackToTop = () => (
  <BacktoTop showOnScrollUp showAt={100} speed={1500} easing="easeInOutQuint">
    <ToTop><IconToTop style={{ fill: "#c6a15a"}}/></ToTop>
  </BacktoTop>
)

export default BackToTop
