import React from "react"
import styled from "styled-components"
import { Row, Col } from "./style/Global"
import { Menu, Item } from "./Menu"
import Headroom from "react-headroom"
import MenuMobile from "../components/MobileMenu"
import MenuIcon from "../components/MenuIcon"
import LogoSvg from "../../src/images/logo.svg"
import FacebookIcon from '../assets/facebook.svg'

const NavContainer = styled(Headroom)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  padding: 1rem 2rem;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 12;
  height: auto !important;
  .headroom--pinned {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #000 !important;
    padding: 1rem 2rem;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 12;
    border-bottom: 1px solid ${props => props.theme.primaryColor};
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      padding: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 0;
  }
`

const DesktopMenu = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }
`

const MobileMenu = styled.div`
  display: none;
  padding: 0 1rem;
  height: 10vh;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const LogoImg = styled.img`
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 100px;
`

const SocialMedia = styled(Col)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`

class Nav extends React.Component {
  state = {
    mobileMenu: false,
    whiteBackground: false,
  }

  render() {

    const data = [
      {
        menuItem: "Početna",
        menuLinkTo: "home",
      },
      {
        menuItem: "O nama",
        menuLinkTo: "aboutUs",
      },
      {
        menuItem: "Proizvodi",
        menuLinkTo: "products",
      },
      {
        menuItem: "Naš razvoj",
        menuLinkTo: "timeline",
      },
      {
        menuItem: "Galerija",
        menuLinkTo: "gallery",
      },
      {
        menuItem: "Nagrade",
        menuLinkTo: "achievements",
      },
      {
        menuItem: "Kontakt",
        menuLinkTo: "contact",
      },
    ]

    return (
      <NavContainer>
        <DesktopMenu>
          <Row>
            <Col width="20" md="15" style={{ position: "relative" }}>
              <LogoImg src={LogoSvg} alt={LogoSvg} />
            </Col>
            <Col width="60" md="50">
              <Menu>
                {data.map((x, index) => {
                  return (
                    <Item section={x.menuLinkTo} key={index}>
                      {x.menuItem}
                    </Item>
                  )
                })}
              </Menu>
            </Col>
            <SocialMedia width="20" md="35">
              <a href="https://www.facebook.com/opgdesa/"><FacebookIcon style={{ maxWidth: 30, height: 'auto', fill: '#c6a15a'}}/></a>
            </SocialMedia>
          </Row>
        </DesktopMenu>
        <MobileMenu
          style={
            !this.state.mobileMenu
              ? { background: "transparent" }
              : { background: "#111" }
          }
        >
          <LogoImg src={LogoSvg} alt={LogoSvg} />

          <MenuIcon
            opened={this.state.mobileMenu ? "open" : null}
            click={this.openMenuHandler}
          />
        </MobileMenu>
        <MenuMobile
          show={this.state.mobileMenu}
          data={data}
          click={this.closeMenuHandler}
        />
      </NavContainer>
    )
  }

  openMenuHandler = () => {
    this.setState(prevState => {
      return { mobileMenu: !prevState.mobileMenu }
    })
  }

  closeMenuHandler = () => {
    this.setState({ mobileMenu: false })
  }
}
export default Nav
