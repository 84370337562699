import React from "react"
import Layout from "../components/Layout"
import AboutUs from "../components/sections/aboutUs"
import ProductQuality from "../components/sections/productQuality"
import VideoSection from "../components/sections/VideoSection"
import Products from "../components/sections/products"
import TimelineSec from "../components/sections/timeline"
import Gallery from "../components/sections/gallery"
import Achievements from "../components/sections/achievements"
import Contact from "../components/sections/contact"
import Header from "../components/Header"
import Footer from "../components/Footer"
import BackToTop from "../components/BackToTop"
import { ScrollingProvider, Section } from "react-scroll-section"
import "animate.css/animate.min.css"
import { ModalProvider } from "styled-react-modal"

const IndexPage = () => (
  <ModalProvider>
    <Layout>
      <ScrollingProvider scrollBehavior="smooth">
        <Section id="home">
          <Header />
        </Section>
        <Section id="aboutUs">
          <AboutUs />
        </Section>
        <ProductQuality />
        <VideoSection />
        <Section id="products">
          <Products />
        </Section>
        <Section id="timeline">
          <TimelineSec />
        </Section>
        <Section id="gallery">
          <Gallery />
        </Section>
        <Section id="achievements">
          <Achievements />
        </Section>
        <Section id="contact">
          <Contact />
        </Section>
        <Footer />
      </ScrollingProvider>
      <BackToTop />
    </Layout>
  </ModalProvider>
)

export default IndexPage
