import React from "react"
import styled from "styled-components"
import { Menu, Item } from "./Menu"

const MenuContainer = styled.div`
  background: rgb(17, 17, 17);
  position: relative;
  margin: 0 -1rem;
  overflow: hidden;
  transition: all linear 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const MobileMenu = ({ click, show, data, intl }) => (
  <MenuContainer
    style={
      !show
        ? { height: "0", borderBottom: "none" }
        : { height: "277px", borderBottom: "1px solid #000" }
    }
   >
    <Menu onClick={click}>
      {data.map((x, index) => {
        return (
          <Item section={x.menuLinkTo} key={index}>
            {x.menuItem}
          </Item>
        )
      })}
    </Menu>   
  </MenuContainer>
)

export default MobileMenu
