import React, {useState} from "react"
import styled from "styled-components"
import {
  Container,
  Section,
  Title,
  Subtitle,
} from "../style/Global"
import videoBg from "../../images/video-bg.jpg"
import playButtonIcon from "../../images/play_button.png"
import Modal from "styled-react-modal"
import CloseIcon from "../../assets/close.svg"

const UseCasesContainer = styled(Container)`
  z-index: 0;
  position: relative;
  text-align: center;
`

const SectionVideo = styled(Section)`
  background: url(${videoBg}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    text-align: center;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(
    100deg,
    rgba(0, 0, 0, 0.7),
    rgba(198, 161, 90, 0.5)
  );
  z-index: 0;
`

const PlayButton = styled.img`
  max-width: 90px;
  width: 100%;
  cursor: pointer;
`

const StyledModal = Modal.styled`
  width: 100%;
  max-width: 800px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`

const VideoIframe = styled.iframe`
  width: 100%;
  max-width: 800px;
  height: 600px;
  border: none;
`

const CloseIconStyle = styled(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  max-width: 40px;
  fill: #c6a15a;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    top: 0;
    right: 23px;
    cursor: pointer;
    max-width: 20px;
  }
`

const VideoSection = () => {

  const [isOpen, setIsOpen] = useState(false)

  function toggleModal(e) {
   setIsOpen(!isOpen)
  }

  return (
    <SectionVideo>
      <Overlay />
      <UseCasesContainer>
        <Title reversed center>
          HRT reportaža "Primjer dobrog OPG-a"
        </Title>
        <Subtitle reversed center>
          Pogledajte kratki video prilog
        </Subtitle>
        <PlayButton
          src={playButtonIcon}
          alt={playButtonIcon}
          onClick={toggleModal}
        />
        <StyledModal
          isOpen={isOpen}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
        >
          <VideoIframe
            src="https://www.youtube.com/embed/I1c6uN6lq84"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <CloseIconStyle onClick={toggleModal} />
        </StyledModal>
      </UseCasesContainer>
    </SectionVideo>
  )
}

export default VideoSection
