import styled from "styled-components"
import { Link } from "gatsby-plugin-modal-routing"

export const ButtonLink = styled(Link)`
  border-radius: 50px;
  font-size: 14px;
  display: inline-block;
  border: solid 1px
    ${props =>
      (props.primary ? "#fff" : props.theme.primaryColor) ||
      (props.link ? "none" : props.theme.primaryColor)};
  background-size: 200% auto;
  padding: 0.9rem 2rem;
  color: ${props => props.theme.primaryColor};
  text-decoration: none;
  transition: all linear 0.2s;
  &:hover {
    color: ${props => props.theme.reversedColor};
    transition: all linear 0.2s;
    background-color: ${props => props.theme.primaryColor};
    border: solid 1px
      ${props =>
        (props.primary ? props.theme.primaryColor : props.theme.primaryColor) ||
        (props.link ? "none" : props.theme.primaryColor)};
    };
  }
  &:focus {
    outline:none;
  }
  background-color: ${props => (props.primary ? "#fff" : "transparent")};
  box-shadow: ${props =>
    props.primary ? "0 7px 15px 0 rgba(8, 123, 183, 0.28)" : "none"};
`

export const Button = styled.button`
    cursor:pointer;
    border-radius: 50px;
    font-size: 14px;
    display: inline-block;
    border: solid 1px
      ${props =>
        (props.primary ? "#000" : props.theme.primaryColor) ||
        (props.link ? "none" : props.theme.primaryColor)};
    background-size: 200% auto;
    padding: 0.9rem 2rem;
    color: ${props => props.theme.primaryColor};
    text-decoration: none;
    transition: all linear 0.2s;
    &:hover {
      color: ${props => props.theme.reversedColor};
      transition: all linear 0.2s;
      background-color: ${props => props.theme.primaryColor};
      border: solid 1px
        ${props =>
          (props.primary
            ? props.theme.primaryColor
            : props.theme.primaryColor) ||
          (props.link ? "none" : props.theme.primaryColor)};
      };
    }
    &:focus {
      outline:none;
    }
    background-color: ${props => (props.primary ? "#000" : "transparent")};
    box-shadow: ${props =>
      props.primary ? "0 7px 15px 0 rgba(8, 123, 183, 0.28)" : "none"};
`


export const ButtonInput = styled.input`
    cursor:pointer;
    border-radius: 50px;
    font-size: 14px;
    display: inline-block;
    border: solid 1px
      ${props =>
        (props.primary ? "#000" : props.theme.primaryColor) ||
        (props.link ? "none" : props.theme.primaryColor)};
    background-size: 200% auto;
    padding: 0.9rem 2rem;
    color: ${props => props.theme.primaryColor};
    text-decoration: none;
    transition: all linear 0.2s;
    &:hover {
      color: ${props => props.theme.reversedColor};
      transition: all linear 0.2s;
      background-color: ${props => props.theme.primaryColor};
      border: solid 1px
        ${props =>
          (props.primary
            ? props.theme.primaryColor
            : props.theme.primaryColor) ||
          (props.link ? "none" : props.theme.primaryColor)};
      };
    }
    &:focus {
      outline:none;
    }
    background-color: ${props => (props.primary ? "#000" : "transparent")};
    box-shadow: ${props =>
      props.primary ? "0 7px 15px 0 rgba(8, 123, 183, 0.28)" : "none"};
`
