import React from "react"
import styled from "styled-components"
import { Section, Container, Row, Col, Title, Subtitle } from "../style/Global"
import { ButtonInput } from "../style/Button"

const ContactSection = styled(Section)`
  padding: 5rem 0;
  margin-bottom: 0;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 0 2rem;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding:0;
  }
`

const Input = styled.input`
  font-family: inherit;
  width: auto;
  border-radius: 35px;
  padding: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  &:focus {
    border: 2px solid ${props => props.theme.primaryColor};
    outline: none;
  }
`

const Textarea = styled.textarea`
  font-family: inherit;
  width: auto;
  border-radius: 35px;
  padding: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  &:focus {
    border: 2px solid ${props => props.theme.primaryColor};
    outline: none;
  }
`

const Contact = () => {
  return (
    <ContactSection>
      <Container>
        <Title center>Kontakt</Title>
        <Subtitle center>
          Ukoliko ste zainteresirani saznati nešto više o proizvodima, pošaljite
          Vaš upit putem forme ili direktno putem naših kontakt podataka
        </Subtitle>
        <Row>
          <Col width="50">
            <Form
              action="https://formspree.io/tihomir.cavka1@gmail.com"
              method="POST"
            >
              <Input
                type="text"
                name="name"
                placeholder="Upišite vaše puno ime"
                required
              />
              <Input
                type="email"
                name="email"
                placeholder="Upišite vašu email adresu"
                required
              />
              <Textarea
                type="description"
                name="descriptin"
                placeholder="Upišite vaš upit"
                rows="20"
                required
              />
              <ButtonInput type="submit" value="Pošaljite upit" />
            </Form>
          </Col>
          <Col width="50">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2863.3395824057125!2d15.2415840155135!3d44.13824247910758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4761f189b2f43ce5%3A0x38dd8a3098aa1778!2zT1BHIERFxaBB!5e0!3m2!1shr!2shr!4v1579696406134!5m2!1shr!2shr"
              width="100%"
              height="100%"
              frameBorder="0"
            allowFullScreen
            />
          </Col>
        </Row>
      </Container>
    </ContactSection>
  )
}

export default Contact



