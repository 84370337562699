import React from "react"
import styled from "styled-components"
import {
  Section,
  Container,
  Title,
  Text,
  Subtitle,
} from "../style/Global"
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react"
import timelineBg from "../../images/video-bg.jpg"

const TimelineSection = styled(Section)`
  background: url(${timelineBg}) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  padding: 5rem 0;
  margin-bottom: 0;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      100deg,
      rgba(0, 0, 0, 0.6),
      rgba(198, 161, 90, 0.6)
    );
  }
`

const TimelineTitle = styled.h3`
  color: ${props => props.theme.primaryColor};
`

const TimelineSec = () => {
  return (
    <TimelineSection>
      <Container>
        <Title center reversed>
          Naš razvoj
        </Title>
        <Subtitle center reversed>
          Pogledajte našu vremensku crtu
        </Subtitle>
        <Timeline lineColor={"#ddd"}>
          <TimelineItem
            key="001"
            dateInnerStyle={{
              background: "rgba(255,255,255,0.3)",
              color: "#111",
            }}
            dateStyle={{ background: "transparent" }}
            dateText="2010"
            style={{ color: "#c6a15a", background: "transparent" }}
          >
            <TimelineTitle>Početak</TimelineTitle>
            <Text reversed style={{    background: "rgba(0,0,0,0.6)",
    padding: "1rem"}}>
              2010. smo osnovali služnost na šumskom zemljištu, uz velike napore
              isto smo pretvorili u tlo pogodno za uzgoj maslina. Provedeno je
              čišćenje terena od podzemne i nadzemne vegetacije, ripanje,
              mljevenje kamena koji je potom posebnim strojevima rastresen po
              terenu pa nakon toga frezan i mljeven.
            </Text>
          </TimelineItem>
          <TimelineItem
            key="002"
            dateInnerStyle={{
              background: "rgba(255,255,255,0.3)",
              color: "#111",
            }}
            dateStyle={{ background: "transparent" }}
            dateText="2011"
            style={{ color: "#c6a15a", background: "transparent" }}
          >
            {" "}
            <TimelineTitle>Sadnja maslina</TimelineTitle>
            <Text reversed style={{    background: "rgba(0,0,0,0.6)",
    padding: "1rem"}}>
              Na markiranom terenu bagerima su kopane jame i u sipku zemlju
              pomiješanu s organskim stajskim gnojivom sađene masline i to
              isključivo autothone sorte oblica koja dobro podnosi krševiti
              teren. U masleniku posađeno je 5 000 stabala na površini od 24
              hektara.{" "}
            </Text>
          </TimelineItem>
          <TimelineItem
            key="003"
            dateInnerStyle={{
              background: "rgba(255,255,255,0.3)",
              color: "#111",
            }}
            dateStyle={{ background: "transparent" }}
            dateText="2012"
            style={{ color: "#c6a15a", background: "transparent" }}
          >
            {" "}
            <TimelineTitle>Ekološka samoodrživost</TimelineTitle>
            <Text reversed style={{    background: "rgba(0,0,0,0.6)",
    padding: "1rem"}}>
              2012. godine počinje sljedeći proces ostvarivanja vizije ekološke
              samoodrživosti i samododstatnosti. Iz tog razloga u masleniku su
              smještene razne vrste kokoši koje će svojim djelovanjem smanjiti
              broj štetočina i prirodno prihranjivati masline. A cijelo vrijeme
              čuvaju ih veliki bijeli Maremma ovčari.
            </Text>
          </TimelineItem>
          <TimelineItem
            key="004"
            dateInnerStyle={{
              background: "rgba(255,255,255,0.3)",
              color: "#111",
            }}
            dateStyle={{ background: "transparent" }}
            dateText="2017"
            style={{ color: "#c6a15a", background: "transparent" }}
          >
            {" "}
            <TimelineTitle>Mjere ruralnog razvoja</TimelineTitle>
            <Text reversed style={{    background: "rgba(0,0,0,0.6)",
    padding: "1rem"}}>
              Natječaj za Mjere ruralnog razvoja - Potpora za ulaganja u
              poljoprivredna gospodarstva kako bi u masleniku postavili pokretnu
              farmu kokoši nesilica sa 720 koka i nabavili poljoprivrednu
              mehanizaciju. Montaža suvremenog mobilnog objekta za proizvodnju
              konzumnih jaja je obavljena, a u tijeku je formiranje jata.{" "}
            </Text>
          </TimelineItem>
          <TimelineItem
            key="005"
            dateInnerStyle={{
              background: "rgba(255,255,255,0.3)",
              color: "#111",
            }}
            dateStyle={{ background: "transparent" }}
            dateText="2019"
            style={{ color: "#c6a15a", background: "transparent" }}
          >
            {" "}
            <TimelineTitle>Naša priča se nastavlja ...</TimelineTitle>
          </TimelineItem>
        </Timeline>
      </Container>
    </TimelineSection>
  )
}

export default TimelineSec
