import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "../components/style/Global"
import {Link} from 'gatsby-plugin-modal-routing'
import FacebookIcon from '../assets/facebook.svg'

const FooterSection = styled.footer`
background: #111;
  padding: 2rem 0;
   position: relative; 
`

const FooterTitle = styled.h4`
  color: #ddd;
  text-transform: uppercase;
`

const List = styled.ul`
    margin: 0 ;
    padding: 0;
`

const ListItem = styled.li`
    color: #999;
    line-height: 2rem;
    list-style-type: none;
`

const StyledLink = styled.a`
  color: ${props => props.theme.primaryColor};
  text-decoration: none;
  &:hover {
    color: #999;
  }
`

const CustomLink = styled(Link)`
  color: ${props => props.theme.primaryColor};
  text-decoration: none;
`

const Footer = () => {
  
  return (
    <FooterSection>
      <Container>
        <Row>
          <Col width="25">
            <FooterTitle>O.P.G deša</FooterTitle>
            <List>
              <ListItem>O.P.G. Miodrag Deša</ListItem>
              <ListItem>Prilaz Josipa Torbarine 5</ListItem>
              <ListItem>23 000 Zadar</ListItem>
              <ListItem>OIB: 39311900970</ListItem>
              <ListItem>
              <CustomLink
              title="OPG Deša"
              asModal
                to="/privacy/"
              >
                Privatnost i sigurnost
              </CustomLink>
              </ListItem>
            </List>
          </Col>
          <Col width="25">
            <FooterTitle>Kontakt informacije</FooterTitle>
            <List>
              <ListItem>Miodrag Deša</ListItem>
              <ListItem>Email: <StyledLink href="mailto:miodrag.desa@gmail.com" title="OPG Deša">miodrag.desa@gmail.com</StyledLink></ListItem>
              <ListItem>Mob: + 385 (0)91 5628 145</ListItem>
            </List>
          </Col>
          <Col width="25">
            <FooterTitle>&nbsp;</FooterTitle>
            <List>
            <ListItem>Nadežda Deša</ListItem>
              <ListItem>Email: <StyledLink href="mailto:nadezda.desa1@gmail.com" title="OPG Deša">nadezda.desa1@gmail.com</StyledLink></ListItem>
              <ListItem>Mob: +385 (0)91 564 62 72</ListItem>
            </List>
          </Col>
          <Col width="25">
            <FooterTitle>Društvene mreže</FooterTitle>
            <List>
              <ListItem><a href="https://www.facebook.com/opgdesa/"><FacebookIcon style={{ maxWidth: 30, height: 'auto', fill: '#c6a15a'}}/></a></ListItem>              
            </List>
          </Col>
        </Row>
      </Container>
    </FooterSection>
  )
}

export default Footer
