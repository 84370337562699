import React from "react"
import styled from "styled-components"
import { ButtonLink } from "./style/Button"
import ScrollAnimation from "react-animate-on-scroll"

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding-top: 5vh;
  }
`

const Bottom = styled.div`
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  right: 0;
`

const MainTitle = styled.h1`
  color: #c6a15a;
  margin-bottom: 10px;
  font-size: 39px;
  margin-top: 10px;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    font-size: 24px;
  }
`

const MainSubtitle = styled.h2`
  color: #afa48c;
  margin-top: 10px;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    font-size: 18px;
  }
`


const Headline = styled(ScrollAnimation)`
  text-align: center;
  padding: 0 1rem;
  margin-left: 23rem;
  max-width: 35rem;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    margin-left: 5em;
  }
`

const ProjectPreviewAnimation = styled(ScrollAnimation)`
  z-index: 1;
  position: relative;
`

const Banner = () => {

  return (
    <Root>
      <Headline animateIn="zoomIn" animateOnce>
        <MainTitle>OPG Deša <br />Autohtoni proizvodi vrhunske kvalitete</MainTitle>
        <MainSubtitle>Samoodrživa ekološka poljoprivreda</MainSubtitle>
        <ButtonLink to="#contact" title="OPG Deša">Kontaktirajte nas</ButtonLink>
      </Headline>
      <ProjectPreviewAnimation animateIn="fadeInUp" animateOnce>
      
      </ProjectPreviewAnimation>
      <Bottom>
        <svg viewBox="0 0 1440 50" id="wave">
          <path
            d="M1440,21.2101911 L1440,120 L0,180 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"
            fill="#ffffff"
          />
        </svg>
      </Bottom>
    </Root>
  )
}

export default Banner