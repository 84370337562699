import React from "react"
import styled from "styled-components"
import Nav from "./Nav"
import Banner from "./Banner"
import bg from "../images/bg.jpg"

const Container = styled.header`
  height: 100vh;
  position: relative;
  background: url(${bg}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 1;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    margin-bottom: 7rem;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: linear-gradient(
    100deg,
    rgba(0, 0, 0, 0.3),
    rgba(198, 161, 90, 0.2)
  );
  z-index: -1;
  opacity: 0.5;
`

const Header = () => (
  <Container>
    <Nav />
    <Banner />
    <Overlay />
  </Container>
)

export default Header
